import { getPendingRedeemsCountSelector } from '@patrianna-payments/shared-store/redeem/store/selectors'
import { isReadySkipOtpSelector, skipOtpSelector } from '@patrianna-payments/shared-store/skip-otp/store/selectors'
import type { UserPersonalInfo } from '@patrianna/shared-patrianna-types/store/UserModule'
import { getAccountId } from '@patrianna/shared-utils/getUserAccountId'
import { getUserHash } from '@patrianna/shared-utils/getUserHash'
import { createSelector } from '@reduxjs/toolkit'
import { addDays, differenceInDays, isAfter, isSameDay, parseISO } from 'date-fns'
import { BRAND_MILLIONS } from 'src/config/brands'
import type { ReduxState } from 'src/store/types'
import { getSoftKycAutoCompleteAddressEnabled, sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'
import { getSpecialOfferAmount } from '../shop/selectors'

const getUserRoot = (state: ReduxState) => state?.user
const getUser = (state: ReduxState) => {
  return state.user?.info
}
const getUserCreatedAt = (state: ReduxState) => state.user?.info?.createdAt
const getIsAdminUser = (state: ReduxState) => state.user?.info?.admin
const getUserFirstPurchase = (state: ReduxState) => state.user?.paymentMetaDataInfo?.firstDeposit

const getSrVersion = (state: ReduxState) => state.user?.info?.srVersion
const getAcceptedSrVersion = (state: ReduxState) => state.user?.info?.acceptedSrVersion
const getTcVersion = (state: ReduxState) => state.user?.info?.tcVersion
const getAcceptedTcVersion = (state: ReduxState) => state.user?.info?.acceptedTcVersion
const getPpVersion = (state: ReduxState) => state.user?.info?.ppVersion
const getAcceptedPpVersion = (state: ReduxState) => state.user?.info?.acceptedPpVersion
const getTcVersionGC = (state: ReduxState) => state.user?.info?.tcVersionGC
const getAcceptedTcVersionGC = (state: ReduxState) => state.user?.info?.acceptedTcVersionGC
const getPpVersionGC = (state: ReduxState) => state.user?.info?.ppVersionGC
const getAcceptedPpVersionGC = (state: ReduxState) => state.user?.info?.acceptedPpVersionGC

const getTcVersionNonUSGC = (state: ReduxState) => state.user?.info?.tcVersionNonUSGC
const getAcceptedTcVersionNonUSGC = (state: ReduxState) => state.user?.info?.acceptedTcVersionNonUSGC
const getPpVersionNonUSGC = (state: ReduxState) => state.user?.info?.ppVersionNonUSGC
const getAcceptedPpVersionNonUSGC = (state: ReduxState) => state.user?.info?.acceptedPpVersionNonUSGC
const getIsLoadingInfo = (state: ReduxState) => state.user?.isLoadingInfo

export const getUserPaymentMetaDataInfo = (state: ReduxState) => state.user?.paymentMetaDataInfo

export const getUserFlowIsRunning = (state: ReduxState) => state.user.userFlowIsRunning

export const getUserCountryLoggedOut = (state: ReduxState) => {
  return state.user.location?.country
}

export const getUserCountryLoggedIn = (state: ReduxState) => {
  return state.user.info?.country
}

export const getCurrencyDataFromUserPersonalInfoSelector = createSelector(
  getUser,
  (user): UserPersonalInfo | { currency: 'USD'; country: 'US'; state: null } => {
    return user?.personalInfo || { currency: 'USD', country: 'US', state: null }
  }
)

export const getIsUserCountryUSSelector = createSelector(
  getUserCountryLoggedOut,
  getUserCountryLoggedIn,
  (countryLoggedOut, countryLoggedIn) => {
    const country = countryLoggedIn || countryLoggedOut

    return Boolean(country && country === 'US')
  }
)

export const getUserSelector = createSelector(getUser, (user) => user)
export const getUserModeSelector = createSelector(getUser, (user) => user?.mode)
export const getUserFlowIsRunningSelector = createSelector(getUserFlowIsRunning, (isRunning) => isRunning)

export const oauthUsedSelector = createSelector(getUserSelector, (user) => {
  if (user?.oauth?.length) {
    return true
  }
})

export const getUserXpLevelSelector = createSelector(getUserRoot, (user) => user.xpLevel)
export const getUserXpNextLevelSelector = createSelector(getUserRoot, (user) => {
  const nextLevelName = user?.xpLevel?.level + 1
  const maxLevelIndex = 8

  if (nextLevelName > maxLevelIndex) {
    return 0
  }

  return nextLevelName
})

export const getUserDoNotSendEmailsSelector = createSelector(getUserSelector, (user) => user?.doNotSendEmails || false)
export const getUserDoNotSendPushesSelector = createSelector(getUserSelector, (user) => user?.doNotSendPushes || false)
export const getUserDoNotSendSmsSelector = createSelector(getUserSelector, (user) => user?.doNotSendSms || false)

export const isGuestModeSelector = createSelector(getUserRoot, (user) => user.info?.guest)
export const getUserBirthDateSelector = createSelector(getUserSelector, (user) => user?.birthDate)

export const getUserCreatedAtSelector = createSelector(getUserCreatedAt, (date) => date)

export const needToVerifyEmailSelector = createSelector(getUser, (user) => {
  if (!user) {
    return false
  }

  return user.oauth.length === 0 && !user.emailVerified
})

export const needToVerifyJumioKYCSelector = createSelector(getUser, (user) => {
  if (!user) {
    return false
  }

  return user.status === 'RESTRICTED_BY_GEO_POLICY'
})

export const getUserEmailSelector = createSelector(getUser, (user) => user?.email)
export const getUserRealEmail = createSelector(getUser, (user) => user?.realEmail)
export const isLoggedInSelector = createSelector(getUserSelector, (user) => !!user)

export const getKycStatusSelector = createSelector(getUserSelector, (user) => user?.kycStatus)
export const jumioKycAddressSelector = createSelector(getUserSelector, (user) => user?.kycInfo)
export const jumioKycStateSelector = createSelector(jumioKycAddressSelector, (address) => address?.state?.toUpperCase())
export const isSoftKycSentSelector = createSelector(getUserSelector, (user) => Boolean(user?.softKycInfo))
export const softKycAddressLineSelector = createSelector(getUserSelector, (user) => user?.softKycInfo?.address)
export const softKycFirstNameSelector = createSelector(getUserSelector, (user) => user?.softKycInfo?.firstName)
export const softKycLastNameSelector = createSelector(getUserSelector, (user) => user?.softKycInfo?.lastName)
export const SoftKycStateSelector = createSelector(getUserSelector, (user) => user?.softKycInfo?.state?.toUpperCase())
export const userPhoneNumberSelector = createSelector(getUserSelector, (user) => user?.phoneNumber)
export const getUserRestrictionsSelector = createSelector(getUserSelector, (user) => user?.restrictions)

export const isSoftKycAddressFilledSelector = createSelector(getUserSelector, (user) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const { state, country, firstName, lastName, ...rest } = user?.softKycInfo || {}

  return Boolean(Object.values(rest).some(Boolean))
})

export const getIsSoftKYCAvailableSelector = createSelector(getUserSelector, (user) => {
  const isStateValidate =
    user?.softKycInfo?.country?.toLocaleLowerCase?.() === 'us' ? Boolean(user?.softKycInfo?.state) : true

  return Boolean(
    user?.softKycInfo?.address &&
      user?.softKycInfo?.city &&
      user?.softKycInfo?.firstName &&
      user?.softKycInfo?.lastName &&
      user?.softKycInfo?.country &&
      isStateValidate &&
      user?.softKycInfo?.zip &&
      user?.birthDate?.length
  )
})

export const getSoftKYCInfoSelector = createSelector(getUserSelector, (user) => user?.softKycInfo)

export const getBasePrefillDataFromJumioSoftKyc = createSelector(
  getUserSelector,
  getSoftKYCInfoSelector,
  jumioKycAddressSelector,
  getUserEmailSelector,
  (user, softKyc, jumioKyc, email) => {
    return {
      address: jumioKyc?.address || softKyc?.address || '',
      address2: jumioKyc?.address2 || softKyc?.address2 || '',
      state: jumioKyc?.state?.toUpperCase() || softKyc?.state?.toUpperCase() || '',
      firstName: jumioKyc?.firstName || softKyc?.firstName || user?.firstName || '',
      lastName: jumioKyc?.lastName || softKyc?.lastName || user?.lastName || '',
      city: jumioKyc?.city || softKyc?.city || '',
      country: jumioKyc?.country || softKyc?.country || '',
      zip: jumioKyc?.zip || softKyc?.zip || '',
      phoneNumber: user?.phoneNumber || '',
      email,
    }
  }
)

export const getPrefillDataFromJumioSoftKyc = createSelector(
  getBasePrefillDataFromJumioSoftKyc,
  getUserBirthDateSelector,
  (basePrefillData, birthDate) => {
    const formattedBirthDate = birthDate ? new Date(birthDate) : null
    if (formattedBirthDate) {
      formattedBirthDate.setTime(formattedBirthDate.getTime() + formattedBirthDate.getTimezoneOffset() * 60 * 1000)
    }

    return {
      ...basePrefillData,
      birthDate: formattedBirthDate || undefined,
      day: formattedBirthDate ? new Date(formattedBirthDate).getDate().toString() : '',
      month: formattedBirthDate ? (new Date(formattedBirthDate).getMonth() + 1).toString() : '',
      year: formattedBirthDate ? new Date(formattedBirthDate).getFullYear().toString() : '',
    }
  }
)

export const getPrefillDataFromSoftKyc = createSelector(
  getSoftKYCInfoSelector,
  getUserBirthDateSelector,
  getUserEmailSelector,
  getUserSelector,
  (softKyc, birthDate, email, user) => {
    const formattedBirthDate = birthDate ? new Date(birthDate) : null
    if (formattedBirthDate) {
      formattedBirthDate.setTime(formattedBirthDate.getTime() + formattedBirthDate.getTimezoneOffset() * 60 * 1000)
    }

    return {
      address: softKyc?.address || '',
      address2: softKyc?.address2 || '',
      state: softKyc?.state?.toUpperCase() || '',
      firstName: softKyc?.firstName || user?.firstName || '',
      lastName: softKyc?.lastName || user?.lastName || '',
      city: softKyc?.city || '',
      country: softKyc?.country || 'US',
      zip: softKyc?.zip || '',
      birthDate: formattedBirthDate || undefined,
      day: formattedBirthDate ? new Date(formattedBirthDate).getDate().toString() : '',
      month: formattedBirthDate ? (new Date(formattedBirthDate).getMonth() + 1).toString() : '',
      year: formattedBirthDate ? new Date(formattedBirthDate).getFullYear().toString() : '',
      email,
    }
  }
)

export const getPrefillDataFromUserPersonalInfo = createSelector(getUserSelector, (user): UserPersonalInfo => {
  return {
    firstName: user?.personalInfo?.firstName || '',
    lastName: user?.personalInfo?.lastName || '',
    email: user?.personalInfo?.email || user?.email || '',
    address: user?.personalInfo?.address || '',
    address2: user?.personalInfo?.address2 || '',
    country: user?.personalInfo?.country?.toUpperCase() || '',
    state: user?.personalInfo?.state?.toUpperCase() || '',
    city: user?.personalInfo?.city || '',
    zip: user?.personalInfo?.zip || '',
    birthDate: user?.personalInfo?.birthDate || undefined,
    currency: user?.personalInfo?.currency || 'USD',
  }
})

export const getIsPersonalInfoAddressAvailableSelector = createSelector(getUserSelector, (user): boolean => {
  return Boolean(
    user?.personalInfo?.firstName &&
      user?.personalInfo?.lastName &&
      user?.personalInfo?.address &&
      user?.personalInfo?.city &&
      user?.personalInfo?.state &&
      user?.personalInfo?.country &&
      user?.personalInfo?.zip
  )
})

export const getCurrentLevelPercentageSelector = createSelector(
  getUserXpLevelSelector,
  isLoggedInSelector,
  (xpLevel, loggedIn) => {
    if (!loggedIn || !xpLevel) {
      return 0
    }

    return xpLevel.monthlyLevelProgress
  }
)
// @TODO replace selector by getUserCustomerIdSelector
export const getUserIdSelector = createSelector(getUserSelector, (user) => user?.id)
export const getUserAccountIdSelector = createSelector(getUserSelector, (user) => getAccountId(user))
export const getUserCustomerIdSelector = createSelector(getUserSelector, (user) => user?.customerId)
export const getUserHashSelector = createSelector(getUserSelector, (user) => getUserHash(user))

export const getGoldenKeySelector = createSelector(getUserPaymentMetaDataInfo, (metaData) => {
  if (metaData?.lastDeposit) {
    return differenceInDays(new Date(), parseISO(metaData.lastDeposit)) <= 6
  }

  return false
})

export const isTenDaysSinceLastDepositSelector = createSelector(getUserPaymentMetaDataInfo, (metaData) => {
  if (metaData?.lastDeposit) {
    return differenceInDays(new Date(), parseISO(metaData.lastDeposit)) <= 7
  }

  return false
})

export const isTenDaysSinceRegistrationSelector = createSelector(getUserCreatedAt, (date) => {
  if (date) {
    return differenceInDays(new Date(), parseISO(date)) <= 7
  }

  return false
})

export const getUserSubscriptionsSelector = createSelector(getUserSelector, (user) =>
  user?.subscriptions?.length ? user?.subscriptions : null
)

export const getUserRealEmailSelector = createSelector(getUserRealEmail, (email) => {
  return email || ''
})

export const emailHiddenSelector = createSelector(
  getUserSelector,
  (user) => user?.realEmail?.includes('onlinelotto.com') || user?.realEmail?.includes('pulsz.com')
)

export const facebookConnectedSelector = createSelector(
  getUser,
  isLoggedInSelector,
  (user, loggedIn) => loggedIn && user.oauth.includes('facebook')
)

export const getUserInvitationInfoSelector = createSelector(getUser, (user) => user?.invitation)

export const getSentWelcomeEmailFieldSelector = createSelector(getUserSelector, (user) => {
  return user?.sentWelcomeEmail
})

export const getIsAdminUserSelector = createSelector(getIsAdminUser, (isAdmin) => isAdmin)

export const isKYCRequiredSelector = createSelector(getKycStatusSelector, (kycStatus) => {
  return ['initial', 'in_review', 'declined', 'doc_declined', 'id_confirmed'].includes(kycStatus)
})

export const KYCinReviewSelector = createSelector(getKycStatusSelector, (kycStatus) => {
  return kycStatus === 'doc_review'
})

export const isKYCConfirmedSelector = createSelector(getKycStatusSelector, (kycStatus) => {
  return kycStatus === 'confirmed'
})

export const getUserFirstPurchaseSelector = createSelector(getUserFirstPurchase, (firstPurchase) => firstPurchase)

export const getUserIfLastDepositDurationThirtyDays = createSelector(getUserPaymentMetaDataInfo, (metaData) => {
  if (metaData?.lastDeposit) {
    return differenceInDays(new Date(), parseISO(metaData.lastDeposit)) <= 29
  }

  return false
})

export const getUserCreatedInTheLastThirtyDays = createSelector(getUserSelector, (user) => {
  if (user?.createdAt) {
    return differenceInDays(new Date(), parseISO(user.createdAt)) <= 29
  }

  return false
})

export const getGraphyteEnabledSelector = createSelector(
  getUserIfLastDepositDurationThirtyDays,
  getUserCreatedInTheLastThirtyDays,
  (deposit, createdAt) => deposit || createdAt
)

export const getGraphyteEnabledSCSelector = createSelector(
  getUserIfLastDepositDurationThirtyDays,
  getUserCreatedInTheLastThirtyDays,
  getActiveCurrencyIdSelector,
  (deposit, createdAt, activeCurrency) => (deposit || createdAt) && activeCurrency === 'SC'
)

const getLegalVersionByCountryGC = createSelector(
  getTcVersionGC,
  getTcVersionNonUSGC,
  getPpVersionGC,
  getPpVersionNonUSGC,
  getIsUserCountryUSSelector,
  getAcceptedTcVersionGC,
  getAcceptedTcVersionNonUSGC,
  getAcceptedPpVersionGC,
  getAcceptedPpVersionNonUSGC,
  (
    tcVersionGC,
    tcVersionNonUSGC,
    ppVersionGC,
    ppVersionNonUSGC,
    isUserCountryUS,
    acceptedTcVersionGC,
    acceptedTcVersionNonUSGC,
    acceptedPpVersionGC,
    acceptedPpVersionNonUSGC
  ) => {
    return {
      tcVersionGC: isUserCountryUS ? tcVersionGC : tcVersionNonUSGC,
      ppVersionGC: isUserCountryUS ? ppVersionGC : ppVersionNonUSGC,
      acceptedTcVersionGC: isUserCountryUS ? acceptedTcVersionGC : acceptedTcVersionNonUSGC,
      acceptedPpVersionGC: isUserCountryUS ? acceptedPpVersionGC : acceptedPpVersionNonUSGC,
    }
  }
)

export const srCheckVersionSelector = createSelector(
  getSrVersion,
  getAcceptedSrVersion,
  (srVersion, acceptedSrVersion) => srVersion === acceptedSrVersion
)

export const tcCheckVersionSelector = createSelector(
  getTcVersion,
  getAcceptedTcVersion,
  getLegalVersionByCountryGC,
  sweepstakeEnabledSelector,
  (tcVersion, acceptedTcVersion, { tcVersionGC, acceptedTcVersionGC }, sweepstakeEnabled) =>
    sweepstakeEnabled ? tcVersion === acceptedTcVersion : tcVersionGC === acceptedTcVersionGC
)

export const ppCheckVersionSelector = createSelector(
  getPpVersion,
  getAcceptedPpVersion,
  getLegalVersionByCountryGC,
  sweepstakeEnabledSelector,
  (ppVersion, acceptedPpVersion, { ppVersionGC, acceptedPpVersionGC }, sweepstakeEnabled) =>
    sweepstakeEnabled ? ppVersion === acceptedPpVersion : ppVersionGC === acceptedPpVersionGC
)

export const getAcceptedSrVersionSelector = createSelector(getAcceptedSrVersion, (acceptedSrVersion) => {
  const result = Number(acceptedSrVersion)

  return isNaN(result) ? 0 : result
})

export const skipUpdateLegalsScEnabledSelector = createSelector(
  srCheckVersionSelector,
  ppCheckVersionSelector,
  tcCheckVersionSelector,
  getAcceptedSrVersionSelector,
  (isAcceptedSr, isAcceptedPp, isAcceptedTc, acceptedSrVersion) => {
    return !acceptedSrVersion || (isAcceptedSr && isAcceptedTc && isAcceptedPp)
  }
)

export const skipUpdateLegalsSCDisabledSelector = createSelector(
  ppCheckVersionSelector,
  tcCheckVersionSelector,
  (isAcceptedPp, isAcceptedTc) => {
    return isAcceptedTc && isAcceptedPp
  }
)

export const isUpdateingLegalsSelector = createSelector(getUserRoot, (user) => user.isUpdateingLegals)

export const showOtpPageSelector = createSelector(
  skipOtpSelector,
  needToVerifyEmailSelector,
  getUserSelector,
  isReadySkipOtpSelector,
  (skipOtp, emailNotVerified, user, isReadySkipOtp) =>
    user && !emailNotVerified && !skipOtp && !user.locked && isReadySkipOtp
)

export const getUserLegalRulesSelector = createSelector(getUserSelector, (user) => user?.legalRules)

export const getUserLegalRulesTypeSelector = createSelector(
  getUserSelector,
  sweepstakeEnabledSelector,
  (user, scEnabled) => {
    const notAcceptedRules = user?.legalRules?.map((el) => el.type)

    if (scEnabled) {
      return notAcceptedRules
    }

    // @TODO remove when backend
    // do not send SR policy when user in gold mode
    return notAcceptedRules?.filter((i) => i !== 'SR')
  }
)

export const getUserLegalRulesDateSelector = createSelector(getUserSelector, (user) => {
  return user?.legalRuleDate
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(new Date(user.legalRuleDate))
    : null
})

export const getUserLegalRulesModalSettingSelector = createSelector(
  getUserSelector,
  getUserLegalRulesSelector,
  (user, legalRules) => {
    if (!user || !legalRules) return null
    const settingsPriority: Record<string, number> = {
      Tickbox: 0,
      Popup: 1,
      Nothing: 2,
    }
    const setModalSetting = legalRules.reduce((result, item) => {
      if (!result || settingsPriority[item.setting] < settingsPriority[result.setting]) {
        return item
      }

      return result
    })

    return setModalSetting.setting
  }
)

export const showSpecialOffer = createSelector(
  sweepstakeEnabledSelector,
  getSpecialOfferAmount,
  (isScEnabled, specialOfferAmount) => isScEnabled && !!specialOfferAmount
)

export const shouldShowInviteFriendsModalForPaidUsers = createSelector(
  getUserFirstPurchaseSelector,
  (state: ReduxState, inviteFriendsLastDateOpened?: string) => inviteFriendsLastDateOpened,
  (userFirstPurchase, inviteFriendsLastDateOpened) => {
    if (!userFirstPurchase) {
      return false
    }

    const currentDate = new Date()

    const isFriday = currentDate.getDay() === 5
    const isSameFriday = isSameDay(currentDate, parseISO(inviteFriendsLastDateOpened))

    // show popup if it wasn't shown this friday or if user opens the page another friday
    return isFriday && (!inviteFriendsLastDateOpened || !isSameFriday)
  }
)

export const shouldShowInviteFriendsModalForUnpaidUsers = createSelector(
  getUserFirstPurchaseSelector,
  getUserCreatedAtSelector,
  (userFirstPurchase, userCreatedAt) => {
    if (userFirstPurchase) {
      return false
    }
    const currentDate = new Date()
    const oneDayAfterUserCreated = addDays(new Date(userCreatedAt), 1)

    return isAfter(currentDate, oneDayAfterUserCreated)
  }
)

export const getLiveChatSettingsSelector = createSelector(getUserRoot, (user) => user?.liveChatSettings)

export const getLiveChatDepartmentsSelector = createSelector(
  getLiveChatSettingsSelector,
  (liveChatSettings) => liveChatSettings?.departments
)

export const getLiveChatTagsSelector = createSelector(
  getLiveChatSettingsSelector,
  (liveChatSettings) => liveChatSettings?.tags
)

export const getLiveChatPurchaseFlowEnabledSelector = createSelector(
  getLiveChatSettingsSelector,
  (liveChatSettings) => liveChatSettings?.purchaseFlowEnabled
)

export const getRedeemHistoryEnabledSelector = createSelector(
  isLoggedInSelector,
  sweepstakeEnabledSelector,
  (isLoggedIn, scEnabled) => isLoggedIn && scEnabled && BRAND_MILLIONS
)
export const getRedeemNotificationEnabledSelector = createSelector(
  isLoggedInSelector,
  sweepstakeEnabledSelector,
  getPendingRedeemsCountSelector,
  (isLoggedIn, scEnabled, pendingRedeemsCount) => isLoggedIn && scEnabled && pendingRedeemsCount > 0
)

export const getUserRegisteredCountrySelector = createSelector(getUserSelector, (user) => user?.country)

export const getIsLoadingInfoSelector = createSelector(getIsLoadingInfo, (isLoadingInfo) => isLoadingInfo)

export const getIsEvenAccountIdSelector = createSelector(
  getUserAccountIdSelector,
  (accountId): boolean => Number(accountId) % 2 === 0
)

export const getSoftKycAutoCompleteAddressEnabledSelector = createSelector(
  getSoftKycAutoCompleteAddressEnabled,
  getUserCountryLoggedIn,
  (softKycAutoCompleteAddress, userCountryLoggedIn) =>
    softKycAutoCompleteAddress && userCountryLoggedIn.toUpperCase() === 'US' // @TODO remove US condition after international API will be ready
)

export const isQuickStartAvailable = createSelector(
  sweepstakeEnabledSelector,
  getAcceptedSrVersionSelector,
  (isScAvailable, acceptedSrVersion) => {
    return !acceptedSrVersion && isScAvailable
  }
)

export const getIsSrAccepted = createSelector(
  srCheckVersionSelector,
  isLoggedInSelector,
  sweepstakeEnabledSelector,
  (isAcceptedSr, isLoggedIn, isScAvailable) => {
    return isLoggedIn && (!isScAvailable || isAcceptedSr)
  }
)

export const shouldForceVerifyKYCSelector = createSelector(
  isKYCConfirmedSelector,
  needToVerifyJumioKYCSelector,
  needToVerifyEmailSelector,
  (isKYCConfirmed, needToVerifyJumioKYC, needToVerifyEmail) => {
    const needToVerify = needToVerifyJumioKYC && !needToVerifyEmail

    return needToVerify && !isKYCConfirmed
  }
)
