import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import type { ReduxState, ServiceDependenciesOptions, TypedDispatch } from '.'
import { createAsyncThunk } from '@reduxjs/toolkit'

type PayloadCreator<Returned, ThunkArg, ThunkApiConfig> = Parameters<
  typeof createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>
>[1]
type OptionsCreator<Returned, ThunkArg, ThunkApiConfig> = Parameters<
  typeof createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>
>[2]
export const useAppDispatch = () => useDispatch<TypedDispatch>()
export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector

export const createAppAsyncThunk = <
  Returned,
  ThunkArg = unknown,
  ThunkApiConfig extends { extra: ServiceDependenciesOptions } = { extra: ServiceDependenciesOptions }
>(
  typePrefix: string,
  payloadCreator: PayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: OptionsCreator<Returned, ThunkArg, ThunkApiConfig>
) => createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(typePrefix, payloadCreator, options)
