import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'store/types'

const appInitialized = (state: ReduxState) => state.appConfig.init
const getPasswordPolicy = (state: ReduxState) => state.appConfig.passwordPolicy
const getSweepstakeEnabled = (state: ReduxState) => state.appConfig.sweepstakeEnabled
const getForcementMode = (state: ReduxState) => state.appConfig.forcement
const getIsPWAMessageOpen = (state: ReduxState) => state.appConfig.isPWAMessageOpen
const getLocationData = (state: ReduxState) => state.appConfig.location
const getSoftKycRequiredEnabled = (state: ReduxState) => state.appConfig.softKycRequiredEnabled

export const getSoftKycAutoCompleteAddressEnabled = (state: ReduxState) => state.appConfig.softKycAutoCompleteAddress

export const getIsSoftKycRequiredEnabledSelector = createSelector(
  getSoftKycRequiredEnabled,
  (isSoftKycRequiredEnabled) => isSoftKycRequiredEnabled
)

export const isAppInitializedSelector = createSelector(appInitialized, (initialized: boolean) => initialized)
export const getIsPWAMessageOpenSelector = createSelector(getIsPWAMessageOpen, (isOpen) => isOpen)

export const getPasswordPolicySelector = createSelector(getPasswordPolicy, (pPolicy) => pPolicy)

export const sweepstakeEnabledSelector = createSelector(getSweepstakeEnabled, (sweepstakeEnabled) => sweepstakeEnabled)

export const getLocationDataSelector = createSelector(getLocationData, (location) => location)

export const getForcementModeSelector = createSelector(getForcementMode, (forcement) => forcement)

// @TODO replace isSCVisibleSelector by sweepstakeEnabledSelector
export const isSCVisibleSelector = createSelector(sweepstakeEnabledSelector, (scEnabled) => {
  return scEnabled
})
