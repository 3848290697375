import type { ReduxState } from 'src/store'
import { createSelector } from '@reduxjs/toolkit'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { BRAND_MCLUCK } from 'config/brands'

const getGcGeneratorRoot = (state: ReduxState) => state.gcGenerator

export const getGcGeneratorSettings = (state: ReduxState) => state.gcGenerator.settings

export const getGcGeneratorInterval = createSelector(getGcGeneratorSettings, (settings) => settings.interval)

export const getGcGeneratorAmount = createSelector(getGcGeneratorSettings, (settings) => settings.amount)

export const getGcGeneratorAccepted = createSelector(getGcGeneratorRoot, (gcGenerator) => gcGenerator.accepted)

export const getGcGeneratorAccepting = createSelector(getGcGeneratorRoot, (gcGenerator) => gcGenerator.accepting)

export const getGcGeneratorIntervalDate = createSelector(
  getGcGeneratorInterval,
  getGcGeneratorAccepted,
  (interval, accepted): string | null =>
    interval && accepted ? new Date(Number(interval) + Number(accepted)).toString() : null
)

export const getGcGeneratorEnabledForUser = createSelector(getGcGeneratorRoot, (gcGenerator) => gcGenerator.enabled)

export const getGcGeneratorEnabled = createSelector(
  getGcGeneratorEnabledForUser,
  sweepstakeEnabledSelector,
  (enabled, sweepstakeEnabled) => enabled && !sweepstakeEnabled && BRAND_MCLUCK
)

export const getGcGeneratorLoading = createSelector(getGcGeneratorRoot, (gcGenerator) => gcGenerator.loading)
